<template>
  <div v-if="$apollo.loading">Loading..</div>
  <div v-else>
    <div>
      <div class="main " style="background:#fff;">
        <div class="section">
          <div class="container">
            <div class="row">
              <div class="col-lg-8"></div>
              <div class="col-lg-12">
                <md-card class="bg-secondary h-100">
                  <md-card-content>
                    <h1 class="text-white">Catalog Manager</h1>
                    <p class="text-wrap text-white">
                      You have 1 catalog enabled by default. You can then use
                      this in your store. Enabling expert mode in user settings
                      will allow you to create multiple catalogs. <br />
                    </p>

                    <v-btn
                      v-if="currentUser.expert"
                      href="/pim/channels/new"
                      class="  mr-2"
                      >Add catalog
                    </v-btn>
                  </md-card-content>
                </md-card>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12">
                <v-container fluid>
                  <v-row dense>
                    <v-col v-for="card in channels" :key="card.id" :cols="6">
                      <md-card class="bg-white h-100">
                        <md-card-content>
                          <h6 class="card-category">
                            <md-icon class="text-primary">
                              note
                            </md-icon>
                            Catalog
                          </h6>
                          <h4 class="card-title" v-text="card.name"></h4>

                          <!-- <v-img
                height="150"
                width="150"
                 :src="card.main_image != null ? baseurl + card.main_image.url : ''"

                >   </v-img> -->
                          <p v-html="card.short_description"></p>
                        </md-card-content>
                        <md-card-actions>
                          <div class="author"></div>
                          <div class="stats ml-auto">
                            <v-btn
                              class="ma-2 mr-2"
                              tile
                              color="indigo"
                              light
                              @click="
                                $router.push('/pim/channels/detail/' + card.id)
                              "
                              >View</v-btn
                            >
                            <v-btn
                              class="ma-2"
                              tile
                              light
                              @click="
                                $router.push('/pim/channels/edit/' + card.id)
                              "
                            >
                              <v-icon left>mdi-pencil</v-icon> Edit
                            </v-btn>
                          </div>
                        </md-card-actions>
                      </md-card>
                    </v-col>
                    <!-- <div v-if="currentUser.expert">
                      <v-col cols="6">
                        <md-card class="bg-info h-100" href="/pim/channels/new">
                          <md-card-content>
                            <h5 class="card-category card-category-social"></h5>
                            <h4 class="card-title text-white">
                              Create a New Channel
                            </h4>
                          </md-card-content>
                          <md-card-actions>
                            <div class="author"></div>
                            <div class="stats ml-auto">
                              <v-btn
                                href="/pim/channels/new"
                                class="ma-2"
                                tile
                                light
                              >
                                <v-icon left>mdi-pencil</v-icon> Create New
                              </v-btn>
                            </div>
                          </md-card-actions>
                        </md-card>
                      </v-col>
                    </div> -->
                  </v-row>
                </v-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import GetChannelsBySupplier from '@/graphql/queries/GetChannelsBySupplier.gql'

export default {
  components: {},

  data: () => ({
    cardList: [],
    open: ['public'],
    baseurl: process.env.VUE_APP_STRAPI_API_URL || 'http://localhost:1337',
  }),
  computed: {
    ...mapGetters(['currentUser']),
  },
  apollo: {
    channels: {
      query: GetChannelsBySupplier,
      variables() {
        return {
          supplierId: this.$store.state.auth.user.company_relation,
        }
      },
    },
  },
  // created() {
  //   setTimeout(() => {
  //     this.getChannels({
  //       supplier: this.$store.state.auth.user.company_relation,
  //     }).then((res) => {
  //       this.cardList = res.data;
  //     });

  //   }, 1000);
  // },
  methods: {
    ...mapActions(['getChannels']),
  },
}
</script>
